import React from "react";
import PropTypes from "prop-types";
import PackagesTable from "../../../common/PackagesTable";

const HostingDetails = ({ t, packages }) => (
  <div className="Cloud__details">
    <PackagesTable t={t.www} packages={packages} />
    <PackagesTable t={t.email} packages={packages} />
    <PackagesTable t={t.programming} packages={packages} />
    <PackagesTable t={t.database} packages={packages} />
    <PackagesTable t={t.security} packages={packages} />
  </div>
);

HostingDetails.propTypes = {
  t: PropTypes.object.isRequired,
  packages: PropTypes.array.isRequired
};

export default HostingDetails;
