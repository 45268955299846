import Hosting from "./Hosting";
import { connect } from "react-redux";
import { getHostingList } from "../../../../store/packages/hosting/actions";

const mapStateToProps = state => ({
  hosting: state.hosting
});

const mapDispatchToProps = dispatch => ({
  getHostingList: async () => dispatch(await getHostingList())
});

const HostingContainer = connect(mapStateToProps, mapDispatchToProps)(Hosting);
export default HostingContainer;
